import { BrowserRouter as Router, Routes, Route, NavLink  } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

//  STYLES //
import "./App.css"
//  STYLES END  //

//  SECTIONS  //
import Projects from "./Sections/Projects"
import Cv from "./Sections/Cv"
import Contact from "./Sections/Contact"
//  SECTIONS END  //

//  IMAGES  //
import faceGreen from "./Images/introSvgs/faceGreen.svg"
import faceGreenClean1 from "./Images/introSvgs/faceGreenClean1.svg"
import faceGreenClean2 from "./Images/introSvgs/faceGreenClean2.svg"
import faceRed from "./Images/introSvgs/faceRed.svg"
import faceRedGlitch from "./Images/introSvgs/faceRedGlitch.svg"
import enterButton from "./Images/introSvgs/enterButton.svg"
import enterButtonGlitched from "./Images/introSvgs/enterButtonGlitched.svg"
import chipStable from "./Images/introSvgs/chipStable.svg"
import chipDirtyS1 from "./Images/introSvgs/chipDirtyS1.svg"
import chipDirtyS2 from "./Images/introSvgs/chipDirtyS2.svg"
import chipDirtyS3 from "./Images/introSvgs/chipDirtyS3.svg"
import chipDirtyS4 from "./Images/introSvgs/chipDirtyS4.svg"
import chipDirtyS5 from "./Images/introSvgs/chipDirtyS5.svg"
import chipDirtyS5Blurred from "./Images/introSvgs/chipDirtyS5Blurred.svg"
import chipDirtyS6 from "./Images/introSvgs/chipDirtyS6.svg"
import chipDirtyS7 from "./Images/introSvgs/chipDirtyS7.svg"
import chipDirtyS8 from "./Images/introSvgs/chipDirtyS8.svg"
import chipDirtyS9 from "./Images/introSvgs/chipDirtyS9.svg"
import chipDirtyS9Blurred from "./Images/introSvgs/chipDirtyS9Blurred.svg"
import chipDirtyS10 from "./Images/introSvgs/chipDirtyS10.svg"
import chipDirtyS10Blurred from "./Images/introSvgs/chipDirtyS10Blurred.svg"
import balls from "./Images/introSvgs/balls.svg"
//  IMAGES END  //

function App(){
	useEffect(() => {setTimeout(()=>{
		if(startAnimationButton === "clicked!"){
			chipAnimation(chipImageCounter)
		}
	}, 30)})


	// #intro states
	const [introOpacityStatus, setIntroOpacityStatus] = useState();
	const [introBlockStatus, setIntroBlockStatus] = useState();
	const [homepageTextAnimationStatus, setHomepageTextAnimation] = useState();
	const [consoleMessage, setConsoleMessage] = useState("/ welcome");

	// Closing #intro
	function poweringUp(){
		// Hiding #intro element.
		setIntroOpacityStatus("0");
		setTimeout(() => {
			setIntroBlockStatus("none");
		}, 500);

		// Giving animation class here so that it fires after user get through #intro.
		setHomepageTextAnimation("type");
	}

	// Chip states
	const [chipImageCenterSrc, setChipImageCenterSrc] = useState(faceGreen);
	const [chipImageCenterClassList, setChipImageCenterClassList] = useState("");

	const [chipImageAroundSrc, setChipImageAroundSrc] = useState(chipStable);
	const [chipDirtyAroundClassList, setChipDirtyAroundClassList] = useState("");

	const [enterButtonSource, setEnterButtonSource] = useState(enterButton);
	const [enterButtonClassList, setEnterButtonClassList] = useState("");

	const [startAnimationButton, setStartAnimationButton] = useState("")
	const [chipImageCounter, setChipImageCounter] = useState(0);
 
	function chipAnimation(i){
		if(i === 0){
			setConsoleMessage("/ processing...")
			
			setChipImageCenterSrc(faceRed)
			setChipImageCenterClassList("glitchingFace")

			setChipImageAroundSrc(chipDirtyS1)
			setChipDirtyAroundClassList("glitching")

			setEnterButtonClassList("enterButtonGlitching")
		}
		
		if(i === 1){
			setChipImageAroundSrc(chipDirtyS2)
		}
		
		if(i === 20){
			setConsoleMessage("/ corruption! detected")
			setChipImageAroundSrc(chipDirtyS3)
		}

		if(i === 21){
			setChipImageAroundSrc(chipDirtyS4)
		}

		if(i === 22){
			setChipImageAroundSrc(chipDirtyS5Blurred)
			setEnterButtonSource(enterButtonGlitched)
		}

		if(i === 50){
			setChipImageAroundSrc(chipDirtyS6)
		}

		if(i === 51){
			setChipImageAroundSrc(chipDirtyS7)
		}

		if(i === 52){
			setChipImageAroundSrc(chipDirtyS8)
		}

		if(i === 53){
			setChipImageAroundSrc(chipDirtyS9Blurred)
			setChipImageCenterSrc(faceRedGlitch)
			setChipImageCenterClassList("glitchingFace faceRedGlitch")
		}

		if(i === 60){
			setChipImageAroundSrc(chipDirtyS10Blurred)
		}

		if(i === 89){
			setChipImageAroundSrc(chipDirtyS10)
			setChipImageCenterSrc(faceRed)
			setChipImageCenterClassList("glitchingFace")
		}

		if(i === 90){
			setChipImageAroundSrc(chipDirtyS9)
		}

		if(i === 91){
			setChipImageAroundSrc(chipDirtyS8)
		}

		if(i === 92){
			setChipImageAroundSrc(chipDirtyS7)
		}

		if(i === 93){
			setChipImageAroundSrc(chipDirtyS6)
		}

		if(i === 94){
			setChipImageAroundSrc(chipDirtyS5)
		}

		if(i === 95){
			setChipImageAroundSrc(chipDirtyS4)
		}

		if(i === 96){
			setChipImageAroundSrc(chipDirtyS3)
		}

		if(i === 97){
			setChipImageAroundSrc(chipDirtyS2)
		}

		if(i === 98){
			setChipImageAroundSrc(chipDirtyS1)
		}

		if(i === 100){
			setConsoleMessage("/ system functional")

			setChipImageCenterSrc(faceGreenClean1)
			setChipImageCenterClassList("")

			setChipImageAroundSrc(chipStable)
			setChipDirtyAroundClassList("rotate")

			setEnterButtonSource(enterButton)
			setEnterButtonClassList("")
		}
		
		if(i === 104){
			setChipImageCenterSrc(faceGreenClean2)
		}

		if(i === 106){
			setChipImageCenterSrc(faceGreenClean1)
		}

		if(i === 108){
			setChipImageCenterSrc(faceGreenClean2)
		}

		if(i === 110){
			setChipImageCenterSrc(faceGreenClean1)
		}

		if(i === 112){
			setChipImageCenterSrc(faceGreenClean2)
		}

		if(i === 114){
			setChipImageCenterSrc(faceGreenClean1)
		}

		if(i === 116){
			setChipImageCenterSrc(faceGreenClean2)
		}

		if(i === 118){
			setChipImageCenterSrc(faceGreenClean1)
		}

		if(i === 120){
			setChipImageCenterSrc(faceGreenClean2)
		}

		if(i === 122){
			setChipImageCenterSrc(faceGreenClean1)
		}

		if(i === 124){
			setChipImageCenterSrc(faceGreenClean2)
		}

		if(i === 126){
			setChipImageCenterSrc(faceGreenClean1)
		}

		if(i === 128){
			setChipImageCenterSrc(faceGreenClean2)
		}

		if(i === 130){
			setChipImageCenterSrc(faceGreenClean1)
		}

		if(i === 134){
			setChipImageCenterSrc(faceGreenClean2)
		}

		if(i === 136){
			setChipImageCenterSrc(faceGreenClean1)
		}

		if(i === 138){
			setChipImageCenterSrc(faceGreenClean2)
		}

		if(i === 140){
			poweringUp()
		}

		setChipImageCounter(chipImageCounter + 1)
	}

	return(
		<Router>
			<div className="App">
				<header>
					<h1 id="nameTag">Daniel Kotlowski</h1>
					<nav>
						<NavLink activeclassname="active" to="/">Projects</NavLink >
						<NavLink activeclassname="active" to="cv">CV</NavLink >
						<NavLink activeclassname="active" to="contact">Contact</NavLink >
					</nav>

					<div id="socialBoxMobile">
						<a href="https://www.linkedin.com/in/daniel-kotlowski-990372231/" target="_blank" rel="noreferrer">LinkedIn</a>
						<a href="https://github.com/Dankot2033" target="_blank" rel="noreferrer">GitHub</a>
						<a href="mailto:dankotutopia@gmail.com" target="_blank" rel="noreferrer">Email</a>
					</div>

					<div id="console">
						<p className={homepageTextAnimationStatus}>/ Welcome</p>
						<p className={homepageTextAnimationStatus}>/ Standby..</p>
						<p className={homepageTextAnimationStatus}>/ Processing Completed</p>
						<p className={homepageTextAnimationStatus}>/ Access Granted</p>
					</div>
				</header>

				<Routes>
					<Route path="/" element={<Projects/>} />
					<Route path="cv" element={<Cv/>} />
					<Route path="contact" element={<Contact/>} />
				</Routes>

				<div id="intro" style={{ opacity: introOpacityStatus, display: introBlockStatus}}>
					<div id="chipContainer">
						<div id="chipDirtyContainer">
							<img id="chipDirtyCenter" className={chipImageCenterClassList} src={chipImageCenterSrc} alt="Animation figure"/>
							<img id="chipDirtyAround" className={chipDirtyAroundClassList} src={chipImageAroundSrc} alt="Animation figure"/>
						</div>
					</div>

					<img id="enterButton" src={enterButtonSource} className={enterButtonClassList} onClick={() => setStartAnimationButton("clicked!")} alt="Button"/>

					<div id="introConsole">
						<p className="type">{consoleMessage}</p>
					</div>
				</div>

				<div id="bluryScreen" style={{backgroundImage: `url(${balls})`}}>
					<div className="roamingUpAndDown"></div>
					<div className="roamingUpAndDown2"></div>
				</div>
			</div>
		</Router>
	);
}

export default App;