const Cv = () => {
    return(
        <section id="cv">
            <div className="content">
                <div className="cvSection sectionOpening">
                    <h2>Education</h2>

                    <h3>Högskolan Väst</h3>
                    <p>Webmaster</p>
                    <p>2019 - 2021</p>

                    <h3>Göteborgs Universitet</h3>
                    <p>Japanese</p>
                    <p>2018 - 2019</p>

                    <h3>Mimers Hus</h3>
                    <p>Design och Produktutveckling</p>
                    <p>2013 - 2014</p>

                    <h3>Ale Gymnasium</h3>
                    <p>Design och Produktutveckling</p>
                    <p>2011 - 2013</p>
                </div>

                <div className="cvSection sectionOpening">
                    <h2>Skills</h2>

                    <ul>
                        <li>HTML</li>
                        <li>CSS / SASS</li>
                        <li>JavaScript</li>
                        <li>React / Vue / Angular</li>
                        <li>React Native</li>
                        <li>PHP</li>
                        <li>Wordpress</li>
                        <li>UX / UI</li>
                        <li>Photoshop</li>
                        <li>Clip Studio Paint</li>
                        <li>Figma</li>
                        <li>Elm</li>
                    </ul>
                </div>
                <div className="cvSection sectionOpening">
                    <h2>Languages</h2>

                    <ul>
                        <li>Swedish</li>
                        <li>English</li>
                        <li>Polish</li>
                        <li>Japanese</li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Cv;