//  COMPONENTS  //
import ContactForm from "../Components/ContactForm"
//  COMPONENTS END  //

//  IMAGES  //
import Profile from "../Images/daniel.jpg"
import dymekMobile from "../Images/dymekMobile.svg"
import dymekTablet from "../Images/dymekTablet.svg"
import dymekDesktop from "../Images/dymekDesktop.svg"
//  IMAGES END  /

const Contact = () => {
    return(
        <section id="contact">
            <div className="content sectionOpening">
                <ContactForm/>

                <div id="profile">
                    <img src={Profile} alt="Daniel Kotlowski"/>

                    <div className="dymek">
                        <img src={dymekMobile} alt="Retro text background"/>
                        <p>I'm a Web Developer located in Sweden.</p>
                        <p>Patient problem solver with passion for intuitive, dynamic user interface.</p>
                        <p>Have any questions? Don't hesitate and send me a message.</p>
                    </div>

                    <div className="dymek">
                        <img src={dymekTablet} alt="Retro text background"/>
                        <p>I'm a Web Developer located in Sweden.</p>
                        <p>Patient problem solver with passion for intuitive, dynamic user interface.</p>
                        <p>Have any questions? Don't hesitate and send me a message.</p>
                    </div>

                    <div className="dymek">
                        <img src={dymekDesktop} alt="Retro text background"/>
                        <p>I'm a Web Developer located in Sweden.</p>
                        <p>Patient problem solver with passion for intuitive, dynamic user interface.</p>
                        <p>Have any questions? Don't hesitate and send me a message.</p>
                    </div>

                    <div id="socialBoxDesktop">
                        <a href="https://www.linkedin.com/in/daniel-kotlowski-990372231/" target="_blank" rel="noreferrer">LinkedIn</a>
						<a href="https://github.com/Dankot2033" target="_blank" rel="noreferrer">GitHub</a>
						<a href="mailto:dankotutopia@gmail.com" target="_blank" rel="noreferrer">Email</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;