//  IMAGES  //
import Venitti from "../Images/Venitti.jpg"
import Quubeb from "../Images/Quubeb.png"
import Fotoatelje from "../Images/Fotoatelje.jpg"
import TestOne from "../Images/TestOne.png"
import TestTwo from "../Images/TestTwo.jpg"
import folder from "../Images/folder.svg"
import folder2 from "../Images/folder2.svg"
//  IMAGES END  /

const Projects = () => {
    return(
        <section id="projects">
            <div className="content">
                <h2>Projects</h2>

                <div className="project sectionOpening">
                    <div className="image">
                        <img src={Fotoatelje} alt="Fotoatelje"/>
                    </div>

                    <div className="textContainer">
                        <div className="text">
                            <h3>fotoatelje-mr</h3>
                            <p>React / SASS</p>
                            <p>Photography</p>
                            <a href="https://fotoatelje-mr.se/" target="_blank" rel="noreferrer">Live version</a>
                        </div>

                        <div className="text">
                            <h3>Documentation</h3>
                            <div>
                                <img alt="folder icon" src={folder}/>
                                <img alt="folder icon" src={folder2}/>
                                <img alt="folder icon" src={folder2}/>
                            </div>
                            <a href="https://github.com/Dankot2033/Fotoatelje" target="_blank" rel="noreferrer">github</a>
                        </div>
                    </div>
                </div>

                <div className="project sectionOpening">
                    <div className="image">
                        <img src={Venitti} alt="Fotoatelje"/>
                    </div>

                    <div className="textContainer">
                        <div className="text">
                            <h3>Venitti</h3>
                            <p>Shoper</p>
                            <p>Online Dresses Shop</p>
                            <a href="https://venitti.com/" target="_blank" rel="noreferrer">Live version</a>
                        </div>
                    </div>
                </div>

                <div className="project sectionOpening">
                    <div className="image">
                        <img src={Quubeb} alt="Quubeb"/>
                    </div>

                    <div className="textContainer">
                        <div className="text">
                            <h3>Quubeb</h3>
                            <p>HTML / SASS / Figma</p>
                            <p>Illustrations</p>
                            <a href="https://dankot.se/projects/Quubeb/" target="_blank" rel="noreferrer">Live version</a>
                        </div>

                        <div className="text">
                            <h3>Documentation</h3>
                            <div>
                                <img alt="folder icon" src={folder}/>
                                <img alt="folder icon" src={folder2}/>
                                <img alt="folder icon" src={folder2}/>
                            </div>
                            <a href="https://github.com/Dankot2033/Quube" target="_blank" rel="noreferrer">github</a>
                        </div>
                    </div>
                </div>

                <div className="project sectionOpening">
                    <div className="image">
                        <img src={TestOne} alt="TestOne"/>
                    </div>

                    <div className="textContainer">
                        <div className="text">
                            <h3>Funny Business</h3>
                            <p>HTML / SASS</p>
                            <p>Funny Business</p>
                            <a href="https://dankot.se/projects/TestOne/" target="_blank" rel="noreferrer">Live version</a>
                        </div>

                        <div className="text">
                            <h3>Documentation</h3>
                            <div>
                                <img alt="folder icon" src={folder}/>
                                <img alt="folder icon" src={folder2}/>
                                <img alt="folder icon" src={folder2}/>
                            </div>
                            <a href="https://github.com/Dankot2033/Test-1" target="_blank" rel="noreferrer">github</a>
                        </div>
                    </div>
                </div>

                <div className="project sectionOpening">
                    <div className="image">
                        <img src={TestTwo} alt="TestTwo"/>
                    </div>

                    <div className="textContainer">
                        <div className="text">
                            <h3>L. A. S.</h3>
                            <p>HTML / SASS / Figma</p>
                            <p>L. A. S.</p>
                            <a href="https://dankot.se/projects/TestTwo/" target="_blank" rel="noreferrer">Live version</a>
                        </div>

                        <div className="text">
                            <h3>Documentation</h3>
                            <div>
                                <img alt="folder icon" src={folder}/>
                                <img alt="folder icon" src={folder2}/>
                                <img alt="folder icon" src={folder2}/>
                            </div>
                            <a href="https://github.com/Dankot2033/Test-2" target="_blank" rel="noreferrer">github</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Projects;