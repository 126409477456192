import React ,{useState} from "react";
import emailjs from "emailjs-com";

const ContactForm = () =>{
    const[name, setName] = useState("");
    const[email, setEmail] = useState("");
    const[message, setMessage] = useState("");
    const[emailStatus, setEmailStatus] = useState("");

    //Form submition sends to Emailjs before going to gmail account
    const submitForm=(e)=>{
        e.preventDefault()
        if (name && email && message){
            console.log("Form submitted");
            const serviceId = "service_li0a2xq";
            const templateId = "template_dks4rp8";
            const templateParams = {name: name, email: email, message: message};
            const userId = "iGr3fKysou9b3AwX9";
            emailjs.send(serviceId, templateId, templateParams, userId)
                .then(response => console.log(response))
                .then(error => console.log(error));
            setEmailStatus("Message Sent!");
            console.log("Form submition successful");
        }else{
            setEmailStatus("Something is missing");
            console.log("Form submition unsuccessful");
        }
    }

    return(
        <form onSubmit={submitForm}>
            <div>
                <h3>Name</h3>
                <input type="text" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)}/>
            </div>

            <div>
                <h3>Email</h3>
                <input type="text" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
            </div>
            
            <div>
                <h3>Message</h3>
                <textarea name="message" id="message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
            </div>
            <input type="submit" value="SEND" />
            <h3>{emailStatus}</h3>
        </form>
    );
}

export default ContactForm;